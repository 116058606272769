// app/javascript/controllers/clipboard_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["source", "copyText"];

  copy(event) {
    event.preventDefault();

    const text = this.sourceTarget.textContent.trim();

    navigator.clipboard.writeText(text).then(() => {
      // Show success feedback using the copyText target
      if (this.hasCopyTextTarget) {
        const originalText = this.copyTextTarget.textContent;
        this.copyTextTarget.textContent = "Copied!";

        setTimeout(() => {
          this.copyTextTarget.textContent = originalText;
        }, 1000);
      }
    });
  }
}
