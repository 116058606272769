import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {}

  closeOnFocusOutside({ target }) {
    if (!this.element.open) return;
    if (this.element.contains(target)) return;
    if (target.matches("main")) return;

    this.close();
  }

  closeOnClickOutside({ target }) {
    if (this.element.contains(target)) return;

    this.close();
  }

  disconnect() {
    this.close();
  }

  close() {
    this.element.open = false;
  }
}
